<template>
  <div>
    <!-- {{ statusList }} -->
    <div class="p-3 bg-white">
      <b-tabs content-class="py-3" v-model="tabIndex">
        <b-tab
          v-for="status of statusList"
          :key="status.id"
          :value="status.id"
          @click="onTabChange(status.id, $event)"
        >
          <template #title>
            {{ status.name }}
            <b-badge pill>
              {{ status.count }}
              <span class="sr-only">count items list</span></b-badge
            >
          </template>
          <div class="d-flex justify-content-between">
            <InputText
              v-model="filter.search"
              type="text"
              faIcon="search"
              className="mb-2 bg-second w-50"
              placeholder="ค้นหาลูกค้า"
              @onClickIcon="onSearch"
              textField="telephone"
              @onEnter="onSearch"
            />
          </div>
          <b-table
            striped
            hover
            :busy="isBusy"
            :items="documentList"
            :fields="fields"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(document_no)="data">
              <div
                class="text-center text-black my-2 cursor-pointer text-underline"
                @click="showDetail(data.item.id)"
              >
                {{ data.item.document_no }}
              </div>
            </template>
            <template v-slot:cell(created_time)="data">
              <div class="text-center text-black my-2">
                {{ $moment(data.item.created_time).format("DD/MM/YYYY") }}
                <br />
                {{ $moment(data.item.created_time).format("HH:mm") }}
              </div>
            </template>
            <template v-slot:cell(source_branch.branch_name)="data">
              <div>{{ data.item.source_branch.branch_name }}</div>
              <!-- <br /> -->
              <div>รหัส : {{ data.item.source_branch.branch_id }}</div>
            </template>
            <template v-slot:cell(destination_branch.branch_name)="data">
              <div>{{ data.item.destination_branch.branch_name }}</div>
              <!-- <br /> -->
              <div>รหัส : {{ data.item.destination_branch.branch_id }}</div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex align-items-center justify-content-center">
                <font-awesome-icon
                  @click="toggleDetail(data)"
                  icon="clipboard"
                  title="detail"
                  class="mr-2 pointer color-primary"
                />
                <font-awesome-icon
                  icon="file-export"
                  class="pointer color-primary"
                  @click="exportExcel(data)"
                />
              </div>
            </template>
            <template #row-details="row">
              <b-table
                striped
                hover
                :busy="row.item.isBusy"
                :items="row.item.row_detail"
                :fields="fieldsDetail"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(product_name)="data">
                  <div>
                    {{ data.item.display_name }}

                    <template v-if="data.item.is_serial"
                      >({{ data.item.serial_name }})</template
                    >
                  </div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
          />
        </b-tab>
      </b-tabs>
    </div>
    <b-modal v-model="show" size="xl"> <Info :id="detail" /></b-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
import Info from "../info";
export default {
  components: {
    Pagination,
    InputText,
    Info,
  },
  data() {
    return {
      show: false,
      filter: {
        search: "",
        inventory_transfer_document_id: this.$route.params.id,

        inventory_transfer_status: [], //null == ทั้งหมด

        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      statusList: [],
      documentList: [],
      fields: [
        { label: "วันที่", key: "created_time" },
        { label: "เลขที่เอกสาร", key: "document_no" },
        { label: "สถานะ", key: "status_name" },
        {
          label: "ต้นทาง",
          key: "source_branch.branch_name",
          class: "text-left",
        },
        {
          label: "ปลายทาง",
          key: "destination_branch.branch_name",
          class: "text-left",
        },
        { label: "หมายเหตุ", key: "remark" },
        { label: "", key: "action" },
      ],
      tabIndex: 1,
      isBusy: false,
      isLoading: false,
      fieldsDetail: [
        { label: "#", key: "" },
        { label: "Product", key: "product_name" },
        { label: "Quantity", key: "quantity" },
      ],
      modalCallBack: () => {},
      detail: {},
    };
  },
  watch: {
    tabIndex(val) {},
  },
  created() {
    this.getStatusList();

    // this.getData();
  },
  mounted() {
    if (this.$route.hash) {
      const hash = this.$route.hash;
      const hashParts = hash.split("-");
      console.log(hashParts[1]);
      this.showDetail(hashParts[1]);
    }
  },
  methods: {
    async getData() {
      this.isBusy = true;

      this.documentList = [];
      const res = await this.axios.post(
        `/InventoryTransfer/History/List`,
        this.filter
      );

      this.rows = res.data.detail.total_count;
      this.documentList = res.data.detail.transfer_list.map((el) => {
        return { ...el, row_detail: [], isBusy: false };
      });
      this.isBusy = false;
    },

    async getStatusList() {
      const res = await this.axios.post(
        `/InventoryTransfer/History/ListStatus`,
        this.filter
      );
      this.filter.inventory_transfer_status = [res.data.detail[0].id];
      this.getData();
      this.statusList = res.data.detail;
    },

    handleChangeTake(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getData();
    },
    pagination(val) {
      this.filter.page = val;
      this.getData();
    },
    onSearch() {
      this.getData();
    },
    onTabChange(val, e) {
      this.filter.inventory_transfer_status = val == 4 ? [] : [val];
      this.getData(val);
    },
    // goDetail(id) {
    //   this.$router.push(
    //     `/inventory-transfer/${this.$route.params.id}/detail/${id}`
    //   );
    // },
    async toggleDetail(row) {
      row.item.isBusy = true;
      await this.$nextTick();

      row.toggleDetails();
      if (row.item.row_detail.length == 0) this.getRowDetail(row.item);
      else row.item.isBusy = false;
    },
    async getRowDetail(row) {
      const res = await this.axios(
        `/InventoryTransfer/History/${row.id}/Product`,
        this.filter
      );
      row.row_detail = res.data.detail;
      row.isBusy = false;
      await this.$nextTick();
      return res.data.detail;
    },

    async cancel(id) {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(`/InventoryTransfer/History/Cancel`, {
        inventory_transfer_id: id,
        remark: "test",
      });
      this.getStatusList();
      this.$bus.$emit("hideLoading");
    },
    async confirmTransfer(id) {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/InventoryTransfer/History/ConfirmTransfer`,
        {
          inventory_transfer_id: id,
          remark: "",
        }
      );
      this.getStatusList();
      this.$bus.$emit("hideLoading");
    },
    async confirmRecieve(id) {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/InventoryTransfer/History/ConfirmReceive`,
        {
          inventory_transfer_id: id,
          remark: "",
        }
      );
      this.getStatusList();
      this.$bus.$emit("hideLoading");
    },
    async exportExcel(items) {
      this.$bus.$emit("showLoading");
      const res = await this.axios(
        `/InventoryTransfer/History/${items.item.id}/export`,

        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${items.item.document_no || "Draft"}-${this.$moment().format(
          "DD/MM/YYYY_HH:MM:ss"
        )}(${items.item.status_name}).xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async showDetail(id) {
      this.$bus.$emit("showLoading");

      this.detail = id;
      this.show = true;
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-height {
  height: 40px !important;
}
</style>
